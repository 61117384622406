.contact-section{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.contact-form{
    width:'60%'
}

.iframe{
    margin-left: 2%;
    width: 30%;
    height: 280px;
}

.map-section{
    display: flex;
    flex-direction: row;
    gap: 60px;
    margin-top: 50px;
    flex-wrap: wrap;
}
.opening-hours{
    display: flex;
    flex-direction: column;
    text-align: left;
    display: block;
    float: left;
    padding-left: 60px;
}

.labels{
    display: block;
    clear: both;
    font: 15px/100% "open_sansregular", arial, sans-serif;
    
    padding: 5px 0;
    width: 350px;
}

.span-items{
    display: block;
    float: right;
    width: 200px;
}

.getintouch{
    margin-right: 70px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

@media screen and (max-width: 691px){
    .iframe{
        width: 90%;
    }
}

@media screen and (max-width: 840px){
    .contact-section{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}


@media screen and (max-width: 650px){
    
    
    .contact-form{
        width:'90%'
    }
    .iframe{
        margin-left: unset;
        width: 90%;
    }
    .map-section{
        display: flex;
        flex-direction: column;
        gap: 90px;
        margin-top: 50px;
        align-items: center;
        flex-wrap: initial;
    }
}