.form-app {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* height: 100vh; */
    /* background: linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3) ), url(https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500); */
    background-size: cover;
    background-position: center;
    /* background-color: #07358a2e; */
}

/* Calendar style */
.react-calendar {
  border: 1px solid #2d2d2e28 !important; 
  border-radius: 4px !important;
  margin-top: 10px;
  padding: 3px;
}


.react-calendar__navigation button {
  min-width: 0 !important;
}

/* Stepper properties */

.css-y1ntkk {
  height: unset !important;
}
  
  .form-section{
      
      padding: 0px 30px;
      border-radius: 10px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #2d2d2e28 !important; 
      border-radius: 4px !important;
  }
  
  .booking-tag{
    color: #07358A;
    text-align: center;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  .submit-button{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: #07358A;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }