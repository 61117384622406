.image {
    min-height: 100vh;
    position: relative;
  }
  
  .image .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  
  .content span.img-txt {
    background-color: #333;
    text-transform: uppercase;
    color: #fff;
    padding: 1rem;
    font-size: 1.5rem;
    letter-spacing: 10px;
  }
  
  /* body{
    overflow-x: unset;
  } */
  
  h3 {
    letter-spacing: 6px;
    text-transform: uppercase;
    font: 1.3rem;
    text-align: center;
  }

  
  .text-box {
    text-align: center;
    padding: 3rem 1rem;
    text-align: justify;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(20.5% + 1px) ;
}

.react-multiple-carousel__arrow--left {
    left: calc(20% + 1px) ;
}

.react-multi-carousel-item {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 1220px) {
    .react-multiple-carousel__arrow--right {
        right: calc(18% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(18% + 1px) ;
    }
}

@media screen and (max-width: 1100px) {
    .react-multiple-carousel__arrow--right {
        right: calc(16% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(16% + 1px) ;
    }
}

@media screen and (max-width: 1030px) {
    .react-multiple-carousel__arrow--right {
        right: calc(15% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(15% + 1px) ;
    }
}

@media screen and (max-width: 982px) {
    .react-multiple-carousel__arrow--right {
        right: calc(17.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(17.5% + 1px) ;
    }
}

@media screen and (max-width: 930px) {
    .react-multiple-carousel__arrow--right {
        right: calc(15.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(15.5% + 1px) ;
    }
}

@media screen and (max-width: 876px) {
    .react-multiple-carousel__arrow--right {
        right: calc(13.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(13.5% + 1px) ;
    }
}

@media screen and (max-width: 842px) {
    .react-multiple-carousel__arrow--right {
        right: calc(10.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(10.5% + 1px) ;
    }
}

@media screen and (max-width: 800px) {
    .react-multiple-carousel__arrow--right {
        right: calc(8.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(8.5% + 1px) ;
    }
    .css-1qkp7nd-MuiPaper-root-MuiCard-root {
        max-width:70% ;
    }
}

@media screen and (max-width: 750px) {
    .react-multiple-carousel__arrow--right {
        right: calc(5.5% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(5.5% + 1px) ;
    }
    .css-1qkp7nd-MuiPaper-root-MuiCard-root {
     max-width: 80% !important;   
    }
}

@media screen and (max-width: 520px) {
    .react-multiple-carousel__arrow--right {
        right: calc(1% + 1px) ;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(1% + 1px) ;
    }
}

