.carousel-inner img{
    height: 600px;  
}

.react-multi-carousel-list {
    padding-top: 40px;
    padding-bottom: 100px;
    
    margin-right: 15px;
    background: none ;
    width: 100%;
}
 
.react-multi-carousel-dot-list {

    margin-bottom:30px !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px) !important;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 1000px) {
    .carousel-inner img{
        height: 400px;
    }
    .react-multi-carousel-list {
        margin-left: 0px;
        margin-right: 0px;
    }
    .react-multiple-carousel__arrow--left {
        margin-left: 0px;
        left: 0;
    }
    
    .react-multiple-carousel__arrow--right {
        right: 0;
    }
    /* .css-i2kztw-MuiPaper-root-MuiCard-root {
        margin-left: 41px !important;
    }
    .css-1g35zr-MuiPaper-root-MuiCard-root {
        margin-left: 40.5px !important;
    }
    .css-12nrtqu-MuiPaper-root-MuiCard-root {
        margin-left: 55px !important;
    } */
  }

  /* @media screen and (max-width: 830px) {
    .css-5p8zol-MuiPaper-root-MuiCard-root {
      margin-left: 60px !important;
    }
  }
  @media screen and (max-width: 600px) {
  .css-5p8zol-MuiPaper-root-MuiCard-root {
    margin-left: 0px !important;
  }
}
*/

@media screen and (max-width: 520px) {
    
  .react-multi-carousel-list {
    padding-top: 25px;
    padding-bottom: 100px;
    
    margin-right: 15px;
    background: none ;
    width: 100%;
}
  
  } 