.w3-center.w3-large{
    text-align: center !important;
    font-size: 18px !important;
    padding: 0px 180px;
}

@media screen and (max-width: 935px) {
    .w3-center.w3-large{
        text-align: center !important;
        font-size: 18px !important;
        padding: 0px 120px;
    }
}

@media screen and (max-width: 800px) {
    .w3-center.w3-large{
        text-align: center !important;
        font-size: 18px !important;
        padding: unset;
    }
}

.cards{
    max-height: 16.8rem;
    line-height: 1.3rem;
}

@media screen and (max-width: 1260px) {
    .cards{
        max-height: 17.2rem;     
    }
    
}

@media screen and (max-width: 1180px) {
    .cards{
        max-height: 18.6rem;     
    }
    
}

@media screen and (max-width: 1020px) {
    .cards{
        max-height: 19.6rem;
    }
    
}

@media screen and (max-width: 920px) {
    .cards{
        max-height: 20.6rem;
    }
    
}

@media screen and (max-width: 840px) {
    .cards{
        max-height: 21.6rem;
    }
    
}

@media screen and (max-width: 820px) {
    .cards{
        max-height: 22.6rem;
    }
    
}



@media screen and (max-width: 790px) {
    .cards{
        max-height: 23.6rem;
    }
    
}

@media screen and (max-width: 760px) {
    .cards{
        max-height: 24.6rem;
    }
    
}

@media screen and (max-width: 740px) {
    .cards{
        max-height: 25.6rem;
    }
    
}

@media screen and (max-width: 720px) {
    .cards{
        max-height: 26.6rem;
    }
    
}


@media screen and (max-width: 690px) {
    .cards{
        max-height: 27.6rem;
    }
    
}

@media screen and (max-width: 670px) {
    .cards{
        max-height: 28.6rem;
    }
    
}

@media screen and (max-width: 630px) {
    .cards{
        max-height: 29.8rem;
    }  
}
