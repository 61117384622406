.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-icons-link-three {
    font-size: 24px !important;
    color: white !important;
    display: flex !important
    /* margin: 0 10px;
    border-radius: 50%;
    background: white;
    height: 80px;
    width: 80px; */
  }
  
  .social-icons-link-three.facebook:hover {
    color: #4267B2 !important;
    font-size: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: white;
  }
  
  .social-icons-link-three.instagram:hover {
    color: #8a3ab9 !important;
    font-size: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: white;
  }
  
  .social-icons-link-three.youtube:hover {
    color: #FF0000 !important;
    font-size: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: white;
  }
  
  .social-icons-link-three.twitter:hover {
    color: #00acee !important;
    font-size: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: white;
  }
  
  .social-icons-link-three.linkedin:hover {
    color: #0072b1 !important;
    font-size: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: white;
  }