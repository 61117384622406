
/* Template 3 navbar buttons */

.css-1vllzz9-MuiButtonBase-root-MuiButton-root:hover {
  font-size: 14px;
    border-bottom: 2px solid #f00946;
    background-color: #717a9b8f !important; 
}

.css-11vrmjm-MuiButtonBase-root-MuiButton-root:hover{
  font-size: 14px;
    border-bottom: 2px solid #f00946;
    background-color: #717a9b8f !important;
}

/* For app deploy Template 3 navbar buttons */
.css-plecy4:hover {
  font-size: 14px;
    border-bottom: 2px solid #f00946;
    background-color: #717a9b8f !important;
}

.css-5ga5hq:hover {
  font-size: 14px;
    border-bottom: 2px solid #f00946;
    background-color: #717a9b8f !important;
}

/* Dialog box style */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 1000px !important;
    max-height: calc(100% - 15px) !important;
  }

  .css-1u9r9n7-MuiPaper-root-MuiDialog-paper {
    max-width: unset !important;
  }

  .calendly-div{
    display: flex;
    flex-direction: column;
    min-width: 320px;
    height: 580px;
  }

  @media screen and (max-width: 935px){
    .navbartext{
        font-size: 24px !important;
    }
  }

  @media screen and (max-width: 899px) {
    .AvatarBox{
        display: none;
    }
    .css-1hvm056-MuiTypography-root {
        display: none;
    }
    .css-ia453c-MuiTypography-root {
        display: none;
    }
    .css-aa7kqh{
      display: none;
    }
  }